<template>
  <div>
    <!-- 表单 -->
    <div class="form">
      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>公司名称</span>
        </div>
        <input type="text" v-model="formData.companyName" placeholder="请填写公司名称" />
      </div>

      <!-- 表单项 -->
      <div class="form-item">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>公司简介</span>
        </div>
        <textarea maxlength="300" v-model="formData.info" placeholder="请填写公司简介" />
        <div class="word-count">{{formData.info.length}}/{{wordCount}}</div>
      </div>

      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>统一社会信用代码</span>
        </div>
        <input type="text" v-model="formData.usci" placeholder="请填写统一社会信用代码" />
      </div>

      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>姓名</span>
        </div>
        <input type="text" v-model="formData.username" placeholder="请填写您的姓名" />
      </div>

      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>联系电话</span>
        </div>
        <input type="text" v-model="formData.phoneNumber" placeholder="请填写您的联系电话" />
      </div>

      <!-- 表单项 -->
      <div class="form-item">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>职务</span>
        </div>
        <select v-model="formData.userType" autocomplete="off">
          <option value="" hidden>请选择职务</option>
          <option value="Principal" selected="selected">负责人</option>
          <option value="Contact" >联系人</option>
        </select>
      </div>
    </div>

    <!-- 按钮 -->
    <div class="button-tow">
      <div @click="handleAdd">{{ isUpdate ? '修改' : '提交' }}</div>
      <div @click="handleReset">重置</div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'mint-ui'

export default {
  name: 'MemberJoin',

  data () {
    return {
      isUpdate: false,
      wordCount: 300,
      formData: {
        username: '',
        companyName: '',
        info: '',
        usci: '',
        userType: '',
        phoneNumber: ''
      }
    }
  },

  created () {
    const userInfo = this.$store.state.userInfo
    if (userInfo) {
      this.formData.username = userInfo.name
      this.formData.phoneNumber = userInfo.phoneNumber
    }
    this.getMemberApplication()
  },

  methods: {
    getMemberApplication () {
      this.api.getMemberApplication().then(res => {
        switch (res.code) {
          case 2005:
            break
          case 2006:
            Toast(res.msg)
            this.isUpdate = true
            this.formData = {
              username: res.data.username,
              companyName: res.data.companyName,
              info: res.data.info,
              usci: res.data.usci,
              userType: res.data.userType,
              phoneNumber: res.data.phoneNumber
            }
            break
          case 2007:
            this.$store.commit('clearAll')
            this.$router.replace('/personal-center')
            break
          case 20051:
            Toast(`${res.msg}：${res.data.remark}`)
            this.isUpdate = true
            this.formData = {
              username: res.data.username,
              companyName: res.data.companyName,
              info: res.data.info,
              usci: res.data.usci,
              userType: res.data.userType,
              phoneNumber: res.data.phoneNumber
            }
            break
        }
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getMemberApplication() })
      })
    },
    // 添加入会申请
    handleAdd () {
      const params = this.formData
      if (this.formData.username === '') {
        Toast('姓名不能为空')
        return
      }
      if (this.formData.companyName === '') {
        Toast('公司名称不能为空')
        return
      }
      if (this.formData.info === '') {
        Toast('公司简介不能为空')
        return
      }
      if (this.formData.info.length > this.wordCount) {
        Toast(`公司简介不能超过${this.wordCount}个字`)
        return
      }
      if (this.formData.usci === '') {
        Toast('统一社会信用代码不能为空')
        return
      }
      if (this.formData.userType === '') {
        Toast('请选择用户职务')
        return
      }
      if (this.formData.phoneNumber === '') {
        Toast('联系电话不能为空')
        return
      }
      this.api.addMemberApplication(params).then(res => {
        Toast(res.msg)
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleAdd() })
      })
    },
    // 重置input
    handleReset () {
      this.formData = {
        username: '',
        companyName: '',
        info: '',
        usci: '',
        userType: '',
        phoneNumber: ''
      }
    }
  }
}
</script>

<style scoped>
</style>
